<template>
  <div data-app>
    
    <div class="card card-custom border-lg border-0 border-primary">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/verifikasi/master-data/user"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              id="tab-menu-user"
              class="nav-link cursor-pointer active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              Edit Data
            </a>
          </li>
          
          <li class="nav-item" v-show="this.fd == 'perusahaan' || this.fd == 'all'">
            <a
              id="tab-menu-perusahaan"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              role="tab"
            >
              Perusahaan
              <span v-if="errPerusahaan" class="svg-icon svg-icon-md svg-icon-danger m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          
          <li class="nav-item" v-show="this.fd == 'provinsi'">
            <a
              id="tab-menu-provinsi"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              role="tab"
            >
              Provinsi
              <span v-if="errProvinsi" class="svg-icon svg-icon-md svg-icon-danger m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          
          <li class="nav-item" v-show="this.fd== 'kabupaten'">
            <a
              id="tab-menu-kabupaten"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="3"
              data-toggle="tab"
              role="tab"
            >
              Kabupaten
              <span v-if="errKabupaten" class="svg-icon svg-icon-md svg-icon-danger m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
        </ul>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div v-if="dataOri">
                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Username</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-user-2" 
                              label-for="input-user-2"
                              class="mb-0">
                              <span id="input-user-2" class="form-control form-control-solid">
                                {{ form.username }}
                              </span>
                          </b-form-group>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-user-3" 
                              label-for="input-user-3"
                              class="mb-0">
                              <span id="input-user-3" class="form-control form-control-solid">
                                {{ form.email }}
                              </span>
                          </b-form-group>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="separator separator-dashed my-5 mt-15"></div>
                  <!--begin::Form Group-->
                  <div class="row">
                      <label class="col-xl-3"></label>
                      <div class="col-lg-9 col-xl-7">
                          <h5 class="font-weight-bold mb-6">Biodata User:</h5>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Nama Lengkap</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-user-1" 
                              label-for="input-user-1"
                              class="mb-0">
                              <span id="input-user-1" class="form-control form-control-solid">
                                {{ form.biodata.name }}
                              </span>
                          </b-form-group>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">No.HP</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-user-8" 
                              label-for="input-user-8"
                              class="mb-0">
                              <span id="input-user-8" class="form-control form-control-solid">
                                {{ form.biodata.phonecode.kode_telp ? `+${form.biodata.phonecode.kode_telp}` : '' }} {{ form.biodata.phone }}
                              </span>
                          </b-form-group>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="separator separator-dashed my-5 mt-15"></div>
                  <!--begin::Form Group-->
                  <div class="row">
                      <label class="col-xl-3"></label>
                      <div class="col-lg-9 col-xl-7">
                          <h5 class="font-weight-bold mb-6">Akses User:</h5>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Tipe User</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-user-4" 
                              label-for="input-user-4"
                              class="mb-0">
                              <b-form-radio-group 
                              disabled='disabled'
                              id="input-user-4" 
                              v-model="form.tipe" 
                              name="user-tipe">
                                <b-form-radio v-for="(item, index) in opsTipe" :key="index" :value="item.value">
                                  {{ item.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                          </b-form-group>
                      </div>
                  </div>

                  <!--begin::Form Group-->
                  <div class="form-group row">
                      <label class="col-xl-3 col-lg-3 col-form-label">Role</label>
                      <div class="col-lg-9 col-xl-7">
                          <b-form-group
                              id="input-group-user-5" 
                              label-for="input-user-5"
                              class="mb-0">
                                <span id="input-user-5" class="form-control form-control-solid">
                                  {{ form.role.uraian }}
                                </span>
                          </b-form-group>
                      </div>
                  </div>
                
              </div>
              <div v-else>
                <BulletListLoader></BulletListLoader>
              </div>          
            </b-tab>
            
            <b-tab>
              <div v-if="this.fd == 'perusahaan' || this.fd == 'all'">
                <AksesPerusahaan :idser="idU" :aks="form.role.aks" @error-show="onErrPerusahaan"></AksesPerusahaan>
              </div>
            </b-tab>
            <b-tab>
              <div v-if="this.fd == 'provinsi'">
                <AksesProvinsi :idser="idU" :aks="form.role.aks" @error-show="onErrProvinsi"></AksesProvinsi>
              </div>
            </b-tab>
            <b-tab>
              <div v-if="this.fd =='kabupaten'">
                <AksesKabupaten :idser="idU" :aks="form.role.aks" @error-show="onErrKabupaten"></AksesKabupaten>
              </div>
            </b-tab>
          </b-tabs>
        </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MasterService from "@/core/services/api/master/master.service";
import VerifikasiService from "@/core/services/api/verifikasi/master/user.service";
import ErrorService from "@/core/services/error.service";

import AksesPerusahaan from "@/view/pages/verifikasi/master-data/user/akses/Perusahaan.vue";
import AksesProvinsi from "@/view/pages/verifikasi/master-data/user/akses/Provinsi.vue";
import AksesKabupaten from "@/view/pages/verifikasi/master-data/user/akses/Kabupaten.vue";

export default {
  name: "master-data-user-edit",
  props: {
    idU: {
      required: true
    }
  },
  data() {
    return {
      tabIndex: 0,
      errPerusahaan: false,
      errProvinsi: false,
      errKabupaten: false,
      dataOri: null,
      form: {
        idser: this.idU,
        username: null,
        email: null,
        tipe: null,
        role: null,
        status: null,
        biodata: {
          name: null,
          phone_code: null,
          phone: null,
          avatar: null,
        }
      },
      formPass: {
        idser: this.idU,
        password: null,
        password_confirmation: null
      },
      opsTipe: [],
      redirectTab: true
    }
  },  
  components: {
    BulletListLoader,
    AksesPerusahaan,
    AksesProvinsi,
    AksesKabupaten
  },
  computed: {
    ...mapGetters(["currentUser"]),
    fd () {
      if (!this.dataOri || this.opsTipe.length < 1) return
      const tipe = JSON.parse(this.dataOri).tipe
      return this.opsTipe.find((v)=>{ return v.value == tipe}).fd
    }
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    /**
     * Set current active on route
     * @param id
     */
    setActiveTabRoute(id) {
      const data = JSON.parse(this.dataOri) 
      if (!data.tipe) return
      if (id != 'user' && id != 'password' && id != 'perusahaan' && id != 'provinsi' && id != 'kabupaten') return

      const event = document.getElementById(`tab-menu-${id}`)
      const tab = event.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.getAttribute("data-tab"));

      // set current active tab
      event.classList.add("active");

      this.redirectTab = false
    },
    onErrPerusahaan(val) {
      this.errPerusahaan = val
    },
    onErrProvinsi(val) {
      this.errProvinsi = val
    },
    onErrKabupaten(val) {
      this.errKabupaten = val
    },
    
    getTipeUser() {
      MasterService.getTipeUser()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsTipe = []
                return;
            }

            this.opsTipe = res.data.data
        })
        .catch((err) => {
          this.opsTipe = []
          ErrorService.status(err)
        })
    },
    getData () {
      VerifikasiService.getUserSingle({idser: this.idU})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data

            this.$nextTick(() => {
              if (this.$route.query.tab && this.redirectTab) {
                this.setActiveTabRoute(this.$route.query.tab)
              }
            })
        })
        .catch((err) => ErrorService.status(err))
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data User" }]);
    if (!this.currentUser.menu.verifikasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getTipeUser()
    this.getData()
  },
};
</script>
